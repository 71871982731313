import React, { Component } from "react";
import ReactDOM from "react-dom";

import axios from 'axios';

let token;
const sendApiGetRequest = (route) => {
    return axios.get(route, {
        headers: {
            'Authorization': token
        }
    });
}
const sendApiPostRequest = (route, data) => {
    return axios.post(route, data, {
        headers: {
            'Authorization': token
        }
    });
}

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
        }
    }

    componentDidMount() {
        sendApiGetRequest('/api/fuji/user')
            .then(resp => {
                this.setState({ users: resp.data });
            })
    }

    render() {
        return (
            <div>
                <button onClick={_ => this.props.parent.setState({ screen: 'home' })}>Wróć</button>
                <table className="table is-bordered">
                    <thead>
                        <tr>
                            <td>Nazwa domenowa</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((el, key) => {
                            return (<tr key={key}>
                                <td>{el.name}</td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

class Logs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logs: [],
        }
    }

    componentDidMount() {
        sendApiGetRequest('/api/fuji/logs')
            .then(resp => {
                this.setState({ logs: resp.data });
            })
    }

    render() {
        return (
            <div>
                <button onClick={_ => this.props.parent.setState({ screen: 'home' })}>Wróć</button>
                <table className="table is-bordered">
                    <thead>
                        <tr>
                            <td>Użytkownicy</td>
                            <td>Serwis</td>
                            <td>Metoda</td>
                            <td>Data</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.logs.map((el, key) => {
                            return (<tr key={key}>
                                <td>{el.name}</td>
                                <td>{el.service}</td>
                                <td>{el.method}</td>
                                <td>{el.created_at}</td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

class Card extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card: ''
        };
        this.updateCard = this.updateCard.bind(this);
    }

    componentDidMount() {
        return (
            sendApiGetRequest('/api/fuji/card')
                .then(resp => this.setState({ card: resp.data[0].card }))
        );
    }

    updateCard() {
        return sendApiPostRequest('/api/fuji/card', {
            card: this.state.card,
        }).then(_ => alert('ID karty zaktualizowane'))
            .catch(_ => alert('Coś poszło nie tak'));
    }

    render() {
        return (
            <div>
                <button onClick={_ => this.props.parent.setState({ screen: 'home' })}>Wróć</button>
                <div className="wider">
                    <label className="label">ID karty</label>
                    <input className="input" type="password" value={this.state.card} onChange={e => this.setState({ card: e.target.value })} />
                </div>
                <button onClick={this.updateCard}>Zapisz</button>
            </div>
        )
    }

}

class DevToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dev_token: undefined,
        };
        this.updateCard = this.updateCard.bind(this);
        this.generateToken = this.generateToken.bind(this);
    }

    generateToken() {
        let dev_token = '';
        function dec2hex(dec) {
            return ('0' + dec.toString(16)).substr(-2)
        }
        function generateId(len) {
            var arr = new Uint8Array(len / 2)
            window.crypto.getRandomValues(arr)
            return Array.from(arr, dec2hex).join('')
        }
        dev_token = generateId(45);

        this.setState({ dev_token }, _ => {
            this.updateCard();
        });
    }

    updateCard() {
        return sendApiPostRequest('/api/fuji/user/dev_token', {
            dev_token: this.state.dev_token,
        }).catch(_ => alert('Coś poszło nie tak'));
    }

    render() {
        let { dev_token } = this.state;
        return (
            <div>
                <button onClick={_ => this.props.parent.setState({ screen: 'home' })}>Wróć</button>
                {dev_token && <div className="wider">
                    <label className="label">Generowanie nowego tokenu developera</label>
                    <input className="input" type="text" readOnly="true" value={dev_token} />
                </div>}
                {!dev_token && <button onClick={this.generateToken}>Utwórz nowy token</button>}
            </div>
        )
    }

}

class KnownServices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            service: undefined,
            method: undefined,
            users: [],
            privileges: {},
        }
    }

    componentDidMount() {
        sendApiGetRequest('/api/fuji/known_services')
            .then(resp => {
                this.setState({ services: resp.data });
            });

    }

    render() {
        if (!this.state.service) return this.renderServices();
        return this.renderPrivileges();
    }

    renderServices() {
        return (
            <div>
                <button onClick={_ => this.props.parent.setState({ screen: 'home' })}>Wróć</button>
                <table className="table is-bordered">
                    <thead>
                        <tr>
                            <td>Nazwa serwisu</td>
                            <td>URL</td>
                            <td>Funkcje</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.services.map((el, key) => {
                            return (
                                <tr key={key}>
                                    <td>{el.name}</td>
                                    <td>{el.endpoint}</td>
                                    <td>{el.methods.map((fn, fnKey) => {
                                        return <button onClick={_ => {
                                            sendApiGetRequest('/api/fuji/user')
                                                .then(resp => {
                                                    this.setState({ users: resp.data });
                                                });

                                            this.loadPrivileges();
                                            this.setState({
                                                service: el.name,
                                                method: fn,
                                            });
                                        }} key={fnKey}>{fn}</button>
                                    })}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    loadPrivileges() {
        sendApiGetRequest('/api/fuji/privileges')
            .then(resp => {
                let tmp = {};
                resp.data.forEach(el => {
                    tmp[el.id_user] = tmp[el.id_user] || {};
                    tmp[el.id_user][el.service] = tmp[el.id_user][el.service] || {};
                    tmp[el.id_user][el.service][el.method] = el.id;
                });
                this.setState({ privileges: tmp });
            });
    }

    createPrivilege(id_user) {
        return sendApiPostRequest('/api/fuji/privileges', {
            id_user: id_user,
            method: this.state.method,
            service: this.state.service,
        });
    }

    removePrivilege(id_user) {
        let { privileges, service, method } = this.state;
        return sendApiPostRequest('/api/fuji/privileges/delete', {
            id: privileges[id_user][service][method]
        });
    }

    renderPrivileges() {
        let { privileges, service, method } = this.state;
        return (
            <div>
                <button onClick={_ => this.props.parent.setState({ screen: 'home' })}>Wróć</button>
                <table className="table is-bordered">
                    <thead>
                        <tr>
                            <td>Nazwa domenowa</td>
                            <td>Pozwolenie na {this.state.service}.{this.state.method}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((el, key) => {
                            let id_user = el.id;
                            return (
                                <tr key={key}>
                                    <td>{el.name}</td>
                                    <td>
                                        {privileges[id_user] && privileges[id_user][service] && privileges[id_user][service][method]
                                            ? <button onClick={() => {
                                                this.removePrivilege(el.id).then(_ => this.loadPrivileges());
                                            }} className="button is-success">Jest</button>
                                            : <button onClick={() => {
                                                this.createPrivilege(el.id).then(_ => this.loadPrivileges());
                                            }} className="button is-danger">Nie</button>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: '',
            user: {},
            screen: 'home'
        }
        this.logout = this.logout.bind(this);
        this.openDoors = this.openDoors.bind(this);
        this.callTomaszewHead = this.callTomaszewHead.bind(this);
    }

    componentWillMount() {
        token = localStorage.getItem("token")
        if (!token) return this.navigateToLoginPage();
        this.setState({
            token,
            user: JSON.parse(atob(token.split('.')[1]))
        });
    }

    navigateToLoginPage() {
        axios
            .get('api/fuji/user/auth/url')
            .then(resp => window.location = resp.data.url);
    }

    logout() {
        localStorage.removeItem("token");
        this.componentWillMount();
    }

    openDoors() {
        sendApiPostRequest('/api/fuji/user/function', {
            "service": "doors",
            "method": "open",
            "data": {
                "action": "open the door!"
            }
        })
            .then(_ => alert('Otwieram'))
            .catch(e => alert('Błąd [' + e.message + ']'))
    }

    callTomaszewHead() {
        sendApiPostRequest('/api/fuji/user/function', {
            "service": "head",
            "method": "play",
            "data": {
                "action": "open the door!"
            }
        }).catch(e => alert('Błąd [' + e.message + ']'))
    }

    render() {
        let { user } = this.state;
        return (
            <div>
                <nav>
                    <span className="user_name">Hello, {user.name}!</span>
                    <span className="user_role">{user.role}</span>
                </nav>
                <div id="body">
                    {this.renderBody()}
                </div>
            </div>
        )
    }

    renderBody() {
        let { screen } = this.state;
        let { user } = this.state;
        switch (screen) {
            case 'home':
                return (
                    <div>
                        <button onClick={this.openDoors}>Otwórz drzwi</button>
                        <button onClick={this.callTomaszewHead}>Tomaszew says</button>
                        <button onClick={_ => this.setState({ screen: 'card' })}>Moja karta</button>
                        <button onClick={_ => this.setState({ screen: 'dev_token' })}>Developer</button>
                        {user.role != 'admin' || this.renderAdminBar()}
                        <button className="logout" onClick={this.logout}>Wyloguj</button>
                    </div>
                );
            case 'users':
                return <Users parent={this} />;
            case 'services':
                return <KnownServices parent={this} />;
            case 'logs':
                return <Logs parent={this} />;
            case 'card':
                return <Card parent={this} />;
            case 'dev_token':
                return <DevToken parent={this} />
        }
        throw new Error("No such route: " + screen);
    }

    renderAdminBar() {
        return (
            <div className="adminBar">
                <div>
                    <button onClick={_ => this.setState({ screen: 'users' })}>Użytkownicy</button>
                </div>
                <div>
                    <button onClick={_ => this.setState({ screen: 'services' })}>Uprawnienia</button>
                </div>
                <div>
                    <button onClick={_ => this.setState({ screen: 'logs' })}>Logi</button>
                </div>
            </div>
        )
    }
}

ReactDOM.render(<App />, document.getElementById('root'));;